/*
 * 업무구분: 리크루팅 인증목록
 * 화면 명: MSPRC110M
 * 화면 설명: 리크루팅동의 인증목록
 * 화면 접근권한: 일반사용자(U000)
 */
<template>
  <ur-page-container title="인증선택" :show-title="true" type="subpage" action-type="none">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-agreeArea">
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
          <div class="ns-radio-list">
            <mo-radio value="M" v-model="radioSelect" @input="fn_GoNext">휴대폰</mo-radio>
            <mo-radio value="C" v-model="radioSelect" @input="fn_GoNext">신용카드</mo-radio>
          </div>
        </ur-box-container>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="radioSelect === ''" @click="fn_GoNext">다음</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
    
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: 'MSPRC110M',
    screenId: 'MSPRC110M',
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        radioSelect: '' // 라디오 선택 값
      }
    },
    mounted () {
      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog('MSPRC110M')
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_GoNext () {
        let lv_Vm = this
        window.setTimeout( () => {
          if (lv_Vm.radioSelect === 'M') { // 휴대폰 인증 선택
              lv_Vm.$router.push( { name: 'MSPRC120M' } )
          } else if (lv_Vm.radioSelect === 'C') { // 신용카드 인증 선택
              lv_Vm.$router.push( { name: 'MSPRC130M' } )
          }
        }, 500)
        lv_Vm.$BottomManager.fn_SetBottomVisible(false)
      }
    }
  }
</script>